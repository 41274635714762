.avatar {
    $base-avatar-size: md;
    --avatar-size: #{map-get($avatar-sizes, $base-avatar-size)};
    --avatar-alt-initials-font-size: #{map-get(
            $avatar-alt-initials-sizes,
            $base-avatar-size
        )};
    --avatar-alt-text-font-size: #{map-get(
            $avatar-alt-text-sizes,
            $base-avatar-size
        )};

    position: relative;
    z-index: 0;
    text-align: center;
    background-color: $avatar-default-bg;
    color: $avatar-default-color;
    font-weight: $avatar-font-weight;
    overflow: hidden;
    border-radius: 100%;
    height: var(--avatar-size);
    width: var(--avatar-size);

    .avatar-alt-initials {
        font-size: var(--avatar-alt-initials-font-size);
    }

    .avatar-alt-text {
        font-size: var(--avatar-alt-text-font-size);
    }

    &.avatar {
        @each $size in map-keys($avatar-sizes) {
            &-#{$size} {
                --avatar-size: #{map-get($avatar-sizes, $size)};
                --avatar-alt-initials-font-size: #{map-get(
                        $avatar-alt-initials-sizes,
                        $size
                    )};
                --avatar-alt-text-font-size: #{map-get(
                        $avatar-alt-text-sizes,
                        $size
                    )};
            }
        }
    }

    &-alt-text,
    &-alt-icon,
    &-alt-initials {
        @include absolute-center();
        z-index: -1;
    }

    &-image {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
    }
}

.avatar-group {
    @each $size, $value in $avatar-sizes {
        &-#{$size} {
            & > *:not(:first-child) {
                margin-left: calc(#{$value} * #{$avatar-group-offset} * -1);
            }

            &.fixed-width {
                width: auto;
                min-width: calc(((#{$value} + #{$avatar-group-border-width} * (#{$avatar-group-limit} + 1)) * 3) - (#{$value} * #{$avatar-group-offset} * #{$avatar-group-limit}));
            }
        }
    }

    &-avatar {
        @include transition();

        &:first-child {
            margin-left: -$avatar-group-border-width;
        }

        &:hover {
            z-index: 999 !important; // needs !important tag to overwrite the dynamic inline z-index
            transform: scale(1.1);
        }

        .avatar {
            border: $avatar-group-border;
            box-sizing: content-box;
        }
    }
}

.avatar-group-container {
    margin: -$avatar-group-border-width;
    overflow: visible;
}

.avatar-group-border-transparent {
    border: $avatar-group-border-width solid transparent;
}

.avatar-info {
    min-width: $avatar-info-min-width;
}
