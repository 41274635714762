:root {
    // redefine white and black variables as rgb so we can use them with rgba
    @include rgb-vars(white, 255, 255, 255);
    @include rgb-vars(black, 0, 0, 0);
    @include set-rgb-variable(white);
    @include set-rgb-variable(black);

    // grays
    @include rgb-vars(gray-100, 246, 246, 246);
    @include set-rgb-variable(gray-100);
    @include rgb-vars(gray-200, 231, 231, 231);
    @include set-rgb-variable(gray-200);
    @include rgb-vars(gray-300, 211, 211, 211);
    @include set-rgb-variable(gray-300);
    @include rgb-vars(gray-400, 204, 204, 204);
    @include set-rgb-variable(gray-400);
    @include rgb-vars(gray-500, 166, 166, 166);
    @include set-rgb-variable(gray-500);
    @include rgb-vars(gray-600, 117, 117, 117);
    @include set-rgb-variable(gray-600);
    @include rgb-vars(gray-700, 55, 55, 55);
    @include set-rgb-variable(gray-700);
    @include rgb-vars(gray-800, 41, 41, 41);
    @include set-rgb-variable(gray-800);
    @include rgb-vars(gray-900, 31, 31, 31);
    @include set-rgb-variable(gray-900);

    // set light and dark here as they are not editable as a part of the theme
    --#{$variable-prefix}light-red: var(--#{$variable-prefix}gray-100-red);
    --#{$variable-prefix}light-green: var(--#{$variable-prefix}gray-100-green);
    --#{$variable-prefix}light-blue: var(--#{$variable-prefix}gray-100-blue);

    --#{$variable-prefix}dark-red: var(--#{$variable-prefix}gray-800-red);
    --#{$variable-prefix}dark-green: var(--#{$variable-prefix}gray-800-green);
    --#{$variable-prefix}dark-blue: var(--#{$variable-prefix}gray-800-blue);

    // theme
    @each $color in map-keys($theme-colors) {
        @include set-theme($color);
    }

    // lights
    @each $color in map-keys($theme-colors-light) {
        @include set-extended-theme(#{$color}-light, dark);
    }

    // darks
    @each $color in map-keys($theme-colors-dark) {
        @include set-extended-theme(#{$color}-dark, light);
    }

    // system colors
    --#{$variable-prefix}blue: #0d6efd;
    --#{$variable-prefix}indigo: #6610f2;
    --#{$variable-prefix}purple: #6f42c1;
    --#{$variable-prefix}pink: #d63384;
    --#{$variable-prefix}red: #dc3745;
    --#{$variable-prefix}orange: #fd7e14;
    --#{$variable-prefix}yellow: #ffc107;
    --#{$variable-prefix}green: #198754;
    --#{$variable-prefix}teal: #20c997;
    --#{$variable-prefix}cyan: #0dcaf0;
    --#{$variable-prefix}gray: var(--#{$variable-prefix}gray-500);

    --#{$variable-prefix}blue-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}indigo-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}purple-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}pink-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}red-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}orange-contrast: var(--#{$variable-prefix}black);
    --#{$variable-prefix}yellow-contrast: var(--#{$variable-prefix}black);
    --#{$variable-prefix}green-contrast: var(--#{$variable-prefix}white);
    --#{$variable-prefix}teal-contrast: var(--#{$variable-prefix}black);
    --#{$variable-prefix}cyan-contrast: var(--#{$variable-prefix}black);
    --#{$variable-prefix}gray-contrast: var(--#{$variable-prefix}black);

    // fonts
    --#{$variable-prefix}font-sans-serif: #{inspect($font-family-sans-serif)};
    --#{$variable-prefix}font-monospace: #{inspect($font-family-monospace)};
    --#{$variable-prefix}gradient: #{$gradient};

    // root and body
    --#{$variable-prefix}body-font-family: #{$font-family-base};
    --#{$variable-prefix}body-font-size: #{$font-size-base};
    --#{$variable-prefix}body-font-weight: #{$font-weight-base};
    --#{$variable-prefix}body-line-height: #{$line-height-base};
    --#{$variable-prefix}body-bg: var(--#{$variable-prefix}white);
    --#{$variable-prefix}body-bg-rgb: var(--#{$variable-prefix}white-rgb);
    --#{$variable-prefix}body-bg-muted: var(--#{$variable-prefix}gray-100);
    --#{$variable-prefix}body-bg-muted-rgb: #{var(
            --#{$variable-prefix}gray-100-rgb
        )};
    --#{$variable-prefix}body-bg-strong: var(--#{$variable-prefix}gray-200);
    --#{$variable-prefix}body-bg-strong-rgb: #{var(
            --#{$variable-prefix}gray-200-rgb
        )};
    --#{$variable-prefix}body-color: var(--#{$variable-prefix}gray-900);
    --#{$variable-prefix}body-color-rgb: var(--#{$variable-prefix}gray-900-rgb);
    --#{$variable-prefix}body-color-muted: var(--#{$variable-prefix}gray-600);
    --#{$variable-prefix}body-color-muted-rgb: #{var(
            --#{$variable-prefix}gray-600-rgb
        )};
    --#{$variable-prefix}body-color-strong: var(--#{$variable-prefix}gray-700);
    --#{$variable-prefix}body-color-strong-rgb: #{var(
            --#{$variable-prefix}gray-700-rgb
        )};
    @if $body-text-align != null {
        --#{$variable-prefix}body-text-align: #{$body-text-align};
    }

    // links
    --#{$variable-prefix}link-color: var(--#{$variable-prefix}primary);
    --#{$variable-prefix}link-color-hover: #{var(
            --#{$variable-prefix}primary-hover
        )};

    // borders
    --#{$variable-prefix}border-color: var(--#{$variable-prefix}gray-300);
    --#{$variable-prefix}border-color-muted: #{var(
            --#{$variable-prefix}gray-200
        )};
    --#{$variable-prefix}avatar-group-border-color: #{var(
            --#{$variable-prefix}body-bg
        )};

    // components
    --#{$variable-prefix}component-active-bg: var(
        --#{$variable-prefix}secondary-light
    );
    --#{$variable-prefix}component-active-bg-rgb: var(
        --#{$variable-prefix}secondary-light-rgb
    );
    --#{$variable-prefix}component-active-color: var(
        --#{$variable-prefix}secondary-light-contrast
    );

    // accessibility
    --#{$variable-prefix}primary-accessible: var(--#{$variable-prefix}primary);
    --#{$variable-prefix}secondary-accessible: #{var(
            --#{$variable-prefix}secondary
        )};
    --#{$variable-prefix}success-accessible: var(--#{$variable-prefix}success);
    --#{$variable-prefix}warning-accessible: var(--#{$variable-prefix}warning);
    --#{$variable-prefix}danger-accessible: var(--#{$variable-prefix}danger);
    --#{$variable-prefix}info-accessible: var(--#{$variable-prefix}info);
    --#{$variable-prefix}primary-bg-accessible: var(--#{$variable-prefix}primary-light);
    --#{$variable-prefix}secondary-bg-accessible: #{var(
            --#{$variable-prefix}secondary-light
        )};
    --#{$variable-prefix}success-bg-accessible: var(--#{$variable-prefix}success-light);
    --#{$variable-prefix}warning-bg-accessible: var(--#{$variable-prefix}warning-light);
    --#{$variable-prefix}danger-bg-accessible: var(--#{$variable-prefix}danger-light);
    --#{$variable-prefix}info-bg-accessible: var(--#{$variable-prefix}info-light);

    // utilities
    --#{$variable-prefix}hover-shade-modifier: 0.8;
    --#{$variable-prefix}luminance: 95%;
    --#{$variable-prefix}luminance-dark: 20%;
}
