.skeleton-loader {
    content: '\00a0';
    display: block;
    height: calc($line-height-base * $font-size-base);
    width: 50%;
    overflow: hidden;
    position: relative;
    background-color: $transparent;

    &.skeleton-loader-short {
        width: 33%;
    }

    &.skeleton-loader-long {
        width: 100%;
    }

    &::before,
    &::after {
        content: '';
        height: calc(100% - ($skeleton-loader-margin-y * 2));
        width: 100%;
        display: block;
        position: absolute;
        top: $skeleton-loader-margin-y;
    }

    &::before {
        background-color: $skeleton-loader-bg;
        left: 0%;
    }

    &::after {
        background: linear-gradient(
            90deg,
            $skeleton-loader-bg 0%,
            $skeleton-loader-bg-highlight 50%,
            $skeleton-loader-bg 100%
        );
        left: -100%;
        animation: skeleton-loader 2s linear infinite;
    }

    &:empty {
        display: block !important;
    }
}
