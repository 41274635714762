@use 'wdx';

editor {
    .tox-tinymce {
        border: wdx.$input-border-width solid wdx.$input-border-color;
        border-radius: wdx.$input-border-radius;
        color: wdx.$input-color;
    }

    .tox {
        .tox-toolbar-overlord,
        .tox-edit-area__iframe,
        .tox-edit-area__iframe .mce-content-body,
        .tox-sidebar,
        .tox-statusbar {
            background-color: wdx.$input-bg;
            color: wdx.$input-color;
        }

        .tox-toolbar,
        .tox-editor-header,
        .tox-toolbar-overlord > .tox-toolbar__primary,
        &:not(.tiny-mce-inline) .tox-editor-header {
            background-color: wdx.$body-bg-muted;
            color: wdx.$body-color;
        }

        .tox-editor-header,
        &:not(.tiny-mce-inline) .tox-editor-header {
            box-shadow: none;
            border-bottom: wdx.$input-border-width solid wdx.$input-border-color;
        }

        .tox-tbtn {
            color: wdx.$input-color;

            svg {
                fill: wdx.$input-color;
            }

            &:hover {
                color: wdx.$input-color;
                background-color: wdx.$input-bg;

                svg {
                    fill: wdx.$input-color;
                }
            }
        }
    }
}
